import * as React from "react";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
// import Button from '@mui/material/Button';
import { IconButton, Tooltip } from "@mui/material";
// import MenuIcon from '@mui/icons-material/Menu';
// import ElectricBoltTwoToneIcon from '@mui/icons-material/ElectricBoltTwoTone';
// import ModeNightIcon from '@mui/icons-material/ModeNight';
// import LightModeIcon from '@mui/icons-material/LightMode';
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PageviewIcon from "@mui/icons-material/Pageview";
import SourceIcon from "@mui/icons-material/Source";
// import FolderIcon from '@mui/icons-material/Folder';
import GitHubIcon from "@mui/icons-material/GitHub";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import logoImage from "./logo.png";

import { Link } from "react-scroll";

// import { Link } from 'react-router-dom';

import { useTheme } from "@mui/material/styles";

function Appbar({ toggleTheme, darkModeOn }) {
  const theme = useTheme();
  const bgColors = darkModeOn
    ? theme.palette.primary.dark
    : theme.palette.secondary.dark;

  return (
    <div className="appbar">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          sx={{
            height: 150,
            backgroundColor: bgColors,
          }}
          position="fixed"
          //  enableColorOnDark
        >
          <Toolbar
            id="appbar"
            sx={{
              height: 150,
            }}
          >
            {/* <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    >
                        <ElectricBoltTwoToneIcon /> */}
            {/* </IconButton> */}
            <Typography
              id="logo"
              // variant="h3"
              component="div"
              sx={{ flexGrow: 1, marginLeft: 3 }}
            >
              Kuberneat
            </Typography>

            {/* <Tooltip title='Zeus' arrow placement='bottom'><IconButton color="inherit" size='large' sx={{ '&:hover': { backgroundColor: '#22A39F' } }}>
                        <ElectricBoltTwoToneIcon></ElectricBoltTwoToneIcon>
                            </IconButton></Tooltip>
                        <Tooltip title={darkModeOn ? 'Light' : 'Dark'} arrow placement='bottom'>
                            <IconButton 
                                color="inherit" 
                                size='large' sx={{ '&:hover': { backgroundColor: '#22A39F' } }}
                                onClick={toggleTheme}
                                >
                            {darkModeOn ? <LightModeIcon></LightModeIcon> : <ModeNightIcon></ModeNightIcon>}
                            </IconButton>
                            </Tooltip> */}

            <div className="appbar-icons">
              <Link activeClass="active" smooth spy to="carousel">
                {" "}
                <Tooltip title="Getting Started" arrow placement="bottom">
                  <IconButton
                    color="inherit"
                    size="large"
                    sx={{ "&:hover": { backgroundColor: "#22A39F" } }}
                  >
                    <PlayCircleFilledIcon></PlayCircleFilledIcon>
                  </IconButton>
                </Tooltip>
              </Link>

              <Link activeClass="active" smooth spy to="overview">
                <Tooltip title="Overview" arrow placement="bottom">
                  <IconButton
                    color="inherit"
                    size="large"
                    sx={{ "&:hover": { backgroundColor: "#22A39F" } }}
                  >
                    <PageviewIcon></PageviewIcon>
                  </IconButton>
                </Tooltip>
              </Link>

              <Link activeClass="active" smooth spy to="documentation">
                <Tooltip title="Documentation" arrow placement="bottom">
                  <IconButton
                    color="inherit"
                    size="large"
                    sx={{ "&:hover": { backgroundColor: "#22A39F" } }}
                  >
                    <SourceIcon></SourceIcon>
                  </IconButton>
                </Tooltip>
              </Link>

              <Tooltip
                href={"https://github.com/oslabs-beta/Zeus"}
                target="_blank"
                rel="noreferrer"
                title="GitHub"
                arrow
                placement="bottom"
              >
                <IconButton
                  color="inherit"
                  size="large"
                  sx={{ "&:hover": { backgroundColor: "#22A39F" } }}
                >
                  <GitHubIcon></GitHubIcon>
                </IconButton>
              </Tooltip>

               <Tooltip
                href={"https://www.linkedin.com/company/kuberneat/"}
                target="_blank"
                rel="noreferrer"
                title="LinkedIn"
                arrow
                placement="bottom"
              >
                <IconButton
                  color="inherit"
                  size="large"
                  sx={{ "&:hover": { backgroundColor: "#22A39F" } }}
                >
                   <AccountCircleIcon></AccountCircleIcon>
                </IconButton>
              </Tooltip>



            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}

export default Appbar;
