import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, IconButton, CardActionArea, CardActions } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import PersonPinTwoToneIcon from "@mui/icons-material/PersonPinTwoTone";

import { useTheme } from "@mui/material/styles";

function About({ darkModeOn }) {
  const theme = useTheme();

  return (
    <div id="aboutUs">
      <div className="aboutus">
        <h2
          className="titles"
          style={{ marginBottom: 70, textAlign: "center" }}
        >
          Meet the Team
        </h2>
      </div>

      <div className="about-container">
        <Card
          color="primary"
          sx={{
            width: 225,
            borderRadius: 5,
            padding: 0,
            margin: 2,
            backgroundColor: darkModeOn ? "primary.dark" : "secondary.dark",
            color: darkModeOn
              ? "primary.contrastText"
              : "secondary.contrastText",
          }}
        >
          <CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "row-reverse" }}>
              <Tooltip title="Meet Ed" arrow placement="top">
                <IconButton
                  href={"https://www.linkedin.com/in/edcho720/"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    borderRadius="5"
                    size="medium"
                    variant="outlined"
                    sx={{
                      border: 1,
                      borderColor: "floralwhite",
                      borderRadius: 2,
                      backgroundColor: darkModeOn
                        ? "primary.dark"
                        : "secondary.dark",
                      color: darkModeOn
                        ? "primary.contrastText"
                        : "secondary.contrastText",
                    }}
                    startIcon={<PersonPinTwoToneIcon />}
                  >
                    Meet Ed
                  </Button>
                </IconButton>
              </Tooltip>
            </CardActions>

            <CardMedia
              component="img"
              height="225"
              image="/Ed.png"
              alt="Yaku"
            />

            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ textAlign: "left", color: "primary.contrastText" }}
              >
                Ed Cho
              </Typography>
              <Typography
                variant="h7"
                sx={{ textAlign: "left", fontWeight: "bold", color: "#fab700" }}
              >
                Software Engineer
              </Typography>
            </CardContent>

            <CardActions></CardActions>
          </CardActionArea>
        </Card>

        <Card
          color="primary"
          sx={{
            width: 225,
            borderRadius: 5,
            padding: 0,
            margin: 2,
            backgroundColor: darkModeOn ? "primary.dark" : "secondary.dark",
            color: darkModeOn
              ? "primary.contrastText"
              : "secondary.contrastText",
          }}
        >
          <CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "row-reverse" }}>
              <Tooltip title="Meet John" arrow placement="top">
                <IconButton
                  href={"https://www.linkedin.com/in/john-wroge/"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    size="medium"
                    variant="outlined"
                    sx={{
                      border: 1,
                      borderColor: "floralwhite",
                      borderRadius: 2,
                      backgroundColor: darkModeOn
                        ? "primary.dark"
                        : "secondary.dark",
                      color: darkModeOn
                        ? "primary.contrastText"
                        : "secondary.contrastText",
                    }}
                    startIcon={<PersonPinTwoToneIcon />}
                  >
                    Meet John
                  </Button>
                </IconButton>
              </Tooltip>
            </CardActions>
            <CardMedia
              component="img"
              height="225"
              image="john.png"
              alt="Yaku"
            />

            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ textAlign: "left", color: "primary.contrastText" }}
              >
                John Wroge
              </Typography>
              <Typography
                variant="h7"
                sx={{ textAlign: "left", fontWeight: "bold", color: "#fab700" }}
              >
                Software Engineer
              </Typography>
            </CardContent>

            <CardActions></CardActions>
          </CardActionArea>
        </Card>

        <Card
          color="primary"
          sx={{
            width: 225,
            borderRadius: 5,
            padding: 0,
            margin: 2,
            backgroundColor: darkModeOn ? "primary.dark" : "secondary.dark",
            color: darkModeOn
              ? "primary.contrastText"
              : "secondary.contrastText",
          }}
        >
          <CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "row-reverse" }}>
              <Tooltip title="Meet Aimee" arrow placement="top">
                <IconButton
                  href={"https://www.linkedin.com/in/aqn/"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    size="medium"
                    variant="outlined"
                    sx={{
                      border: 1,
                      borderColor: "floralwhite",
                      borderRadius: 2,
                      backgroundColor: darkModeOn
                        ? "primary.dark"
                        : "secondary.dark",
                      color: darkModeOn
                        ? "primary.contrastText"
                        : "secondary.contrastText",
                    }}
                    startIcon={<PersonPinTwoToneIcon />}
                  >
                    Meet Aimee
                  </Button>
                </IconButton>
              </Tooltip>
            </CardActions>
            <CardMedia
              component="img"
              height="225"
              image="/Amy.png"
              alt="Amy"
            />

            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ textAlign: "left", color: "primary.contrastText" }}
              >
                Aimee Nguyen
              </Typography>
              <Typography
                variant="h7"
                sx={{ textAlign: "left", fontWeight: "bold", color: "#fab700" }}
              >
                Software Engineer
              </Typography>
            </CardContent>

            <CardActions></CardActions>
          </CardActionArea>
        </Card>

        <Card
          color="primary"
          sx={{
            width: 225,
            borderRadius: 5,
            padding: 0,
            margin: 2,
            backgroundColor: darkModeOn ? "primary.dark" : "secondary.dark",
            color: darkModeOn
              ? "primary.contrastText"
              : "secondary.contrastText",
          }}
        >
          <CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "row-reverse" }}>
              <Tooltip title="Meet Harvey" arrow placement="top">
                <IconButton
                  href={"https://www.linkedin.com/in/harvey-li/"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    size="medium"
                    variant="outlined"
                    sx={{
                      border: 1,
                      borderColor: "floralwhite",
                      borderRadius: 2,
                      backgroundColor: darkModeOn
                        ? "primary.dark"
                        : "secondary.dark",
                      color: darkModeOn
                        ? "primary.contrastText"
                        : "secondary.contrastText",
                    }}
                    startIcon={<PersonPinTwoToneIcon />}
                  >
                    Meet Harvey
                  </Button>
                </IconButton>
              </Tooltip>
            </CardActions>

            <CardMedia
              component="img"
              height="225"
              image="/Harvey.jpg"
              alt="Harvey"
            />

            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ textAlign: "left", color: "primary.contrastText" }}
              >
                Harvey Li
              </Typography>
              <Typography
                variant="h7"
                sx={{ textAlign: "left", fontWeight: "bold", color: "#fab700" }}
              >
                Software Engineer
              </Typography>
            </CardContent>

            <CardActions></CardActions>
          </CardActionArea>
        </Card>
      </div>
    </div>
  );
}

export default About;
