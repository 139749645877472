import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    title: "Dashboard",
    label:
      "Here you can view meaningful metrics for your Kubernetes cluster such as CPU and memory metrics",
    imgPath: "/Grafana.png",
  },
  {
    title: "Visualizer",
    label:
      "An interactive and informative web graph for you to visualize your cluster." /*Hover over each node to see more specific metrics for it!*/,
    imgPath: "/Cluster.png",
  },
  {
    title: "Pod and Nodes",
    label:
      "An accordion displaying all pods of your cluster listed out, click on each to see more data.",
    imgPath: "/Node.png" /* place pods image path here */,
  },
];

function Carousel({ darkModeOn }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className="carousel">
      <Box sx={{ maxWidth: 1000, flexGrow: 1 }}>
        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            height: 125,
            bgcolor: darkModeOn ? "primary.dark" : "secondary.dark",
            color: "primary.contrastText",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: 24,
                color: "#fab700",
              }}
            >
              {images[activeStep].title}
            </Typography>

            <Typography>{images[activeStep].label}</Typography>
          </Box>
        </Paper>

        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <div key={step.label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  // id='video'
                  component="img"
                  sx={{
                    maxHeight: 500,
                    display: "block",
                    maxWidth: 1000,
                    overflow: "hidden",
                    width: "100%",
                  }}
                  src={step.imgPath} /* image src */
                  alt={step.label} /* will display alt text if no image */
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>

        <MobileStepper
          sx={
            {
              /* borderRadius: 5 */
            }
          }
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Box>
    </div>
  );
}

export default Carousel;
