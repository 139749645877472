import * as React from "react";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { IconButton, Tooltip } from "@mui/material";
// import { useTheme } from '@mui/material/styles';

function Documentation({ darkModeOn }) {
  // const theme = useTheme();

  const styles = {
    color: darkModeOn ? "floralwhite" : "black",
    fontFamily: "Poppins",
  };

  return (
    <>
      <div id="documentation">
        <div className="documents" style={styles}>
          <h1 className="titles">Documentation</h1>

          <br></br>
          <br></br>
          <br></br>

          <h2>Copy these commands into your terminal:</h2>

          <br></br>

          <h3>Obtain the stateful set of your clusters.</h3>

          <div className={darkModeOn ? "codeBlock" : "codeBlock2"}>
            <code>kubectl get statefulset</code>
            <Tooltip
              title="copy"
              arrow
              placement="top-end"
              color="inherit"
              sx={{ marginRight: 2 }}
            >
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText("kubectl get statefulset")
                }
              >
                <ContentPasteIcon></ContentPasteIcon>
              </IconButton>
            </Tooltip>
          </div>

          <h3>
            Copy the stateful set outputted from your terminal to create the
            yaml files.
          </h3>

          <div className={darkModeOn ? "codeBlock" : "codeBlock2"}>
            <code>
              kubectl describe statefulset
              prometheus-prometheus-kube-prometheus-prometheus > prometheus.yaml
            </code>
            <Tooltip
              title="copy"
              arrow
              placement="top-end"
              color="inherit"
              sx={{ marginRight: 2 }}
            >
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(
                    "kubectl describe statefulset prometheus-prometheus-kube-prometheus-prometheus > prometheus.yaml"
                  )
                }
              >
                <ContentPasteIcon></ContentPasteIcon>
              </IconButton>
            </Tooltip>
          </div>

          <h3>
            Above and below are examples of the syntax to create your files.
          </h3>

          <div className={darkModeOn ? "codeBlock" : "codeBlock2"}>
            <code>
              kubectl describe statefulset
              prometheus-prometheus-kube-operator-alertmanager >
              prometheus-alertmanager.yaml
            </code>
            <Tooltip
              title="copy"
              arrow
              placement="top-end"
              color="inherit"
              sx={{ marginRight: 2 }}
            >
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(
                    "kubectl describe statefulset prometheus-prometheus-kube-operator-alertmanager > prometheus-alertmanager.yaml"
                  )
                }
              >
                <ContentPasteIcon></ContentPasteIcon>
              </IconButton>
            </Tooltip>
          </div>

          <h3>
            To get deployments enter the following command into your terminal:
          </h3>

          <div className={darkModeOn ? "codeBlock" : "codeBlock2"}>
            <code>kubectl get deployment</code>
            <Tooltip
              title="copy"
              arrow
              placement="top-end"
              color="inherit"
              sx={{ marginRight: 2 }}
            >
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText("kubectl get deployment")
                }
              >
                <ContentPasteIcon></ContentPasteIcon>
              </IconButton>
            </Tooltip>
          </div>

          <h3>
            Create a deployment yaml file with the following command below.
            Note, you can name this file however you want. Below is an example:
          </h3>

          <div className={darkModeOn ? "codeBlock" : "codeBlock2"}>
            <code>
              kubectl describe deployment prometheus-kube-prometheus-operator >
              prometheus-operator.yaml
            </code>
            <Tooltip
              title="copy"
              arrow
              placement="top-end"
              color="inherit"
              sx={{ marginRight: 2 }}
            >
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(
                    "kubectl describe deployment prometheus-kube-prometheus-operator > prometheus-operator.yaml"
                  )
                }
              >
                <ContentPasteIcon></ContentPasteIcon>
              </IconButton>
            </Tooltip>
          </div>

          <h3>
            Create another deployment yaml file with the following command. Note
            that you can name this file however you want. Below is an example:
          </h3>

          <div className={darkModeOn ? "codeBlock" : "codeBlock2"}>
            <code>
              kubectl describe deployment prometheus-kube-prometheus-operator >
              prometheus-secret.yaml
            </code>
            <Tooltip
              title="copy"
              arrow
              placement="top-end"
              color="inherit"
              sx={{ marginRight: 2 }}
            >
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(
                    "kubectl describe deployment prometheus-kube-prometheus-operator > prometheus-secret.yaml"
                  )
                }
              >
                <ContentPasteIcon></ContentPasteIcon>
              </IconButton>
            </Tooltip>
          </div>

          <h3>
            Obtain the list of all services in your cluster, by entering the
            following command in your terminal:
          </h3>

          <div className={darkModeOn ? "codeBlock" : "codeBlock2"}>
            <code>kubectl get services > services</code>
            <Tooltip
              title="copy"
              arrow
              placement="top-end"
              color="inherit"
              sx={{ marginRight: 2 }}
            >
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(
                    "kubectl get services > services"
                  )
                }
              >
                <ContentPasteIcon></ContentPasteIcon>
              </IconButton>
            </Tooltip>
          </div>

          <h3>
            Look for the pod deploying to 'prometheus-grafana' followed by the
            ID of the pod. Below is an example:
          </h3>

          <div className={darkModeOn ? "codeBlock" : "codeBlock2"}>
            <code>
              kubectl logs prometheus-grafana-6fdd6868b4-hgtp4 -c grafana
            </code>
            <Tooltip
              title="copy"
              arrow
              placement="top-end"
              color="inherit"
              sx={{ marginRight: 2 }}
            >
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(
                    "kubectl logs prometheus-grafana-[id # of pod]-c grafana"
                  )
                }
              >
                <ContentPasteIcon></ContentPasteIcon>
              </IconButton>
            </Tooltip>
          </div>

          <h3>Open Grafana to a port of your choosing with the command:</h3>

          <div className={darkModeOn ? "codeBlock" : "codeBlock2"}>
            <code>
              kubectl port-forward deployment/prometheus-grafana [port of your
              choosing]:3000
            </code>
            <Tooltip
              title="copy"
              arrow
              placement="top-end"
              color="inherit"
              sx={{ marginRight: 2 }}
            >
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(
                    "kubectl port-forward deployment/prometheus-grafana [port of your choosing]:3000"
                  )
                }
              >
                <ContentPasteIcon></ContentPasteIcon>
              </IconButton>
            </Tooltip>
          </div>

          <h3>
            You will be prompted to login into Grafana. By default, the username
            and password is set to admin and prom-operator, respectively.
          </h3>

          <div></div>
          <br></br>
          <br></br>
          <br></br>

          <h1 className="titles">Accessing Grafana</h1>

          <br></br>
          <br></br>
          <br></br>

          <h3>
            To ensure all your pods are accessible and running, enter in the
            following command into your terminal:
          </h3>

          <div className={darkModeOn ? "codeBlock" : "codeBlock2"}>
            <code>kubectl get po -A</code>
            <Tooltip
              title="copy"
              arrow
              placement="top-end"
              color="inherit"
              sx={{ marginRight: 2 }}
            >
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText("kubectl get po -A")
                }
              >
                <ContentPasteIcon></ContentPasteIcon>
              </IconButton>
            </Tooltip>
          </div>

          <h3>
            Obtain the specific deployments in your cluster with the following
            command:
          </h3>

          <div className={darkModeOn ? "codeBlock" : "codeBlock2"}>
            <code>
              kubectl get deployment --namespace=[name of your namespace]
            </code>
            <Tooltip
              title="copy"
              arrow
              placement="top-end"
              color="inherit"
              sx={{ marginRight: 2 }}
            >
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(
                    "kubectl get deployment --namespace=[name of your namespace]"
                  )
                }
              >
                <ContentPasteIcon></ContentPasteIcon>
              </IconButton>
            </Tooltip>
          </div>

          <h3>
            Open Prometheus to a port of your choosing with the following
            command:
          </h3>

          <div className={darkModeOn ? "codeBlock" : "codeBlock2"}>
            <code>
              kubectl port-forward
              prometheus-prometheus-kube-prometheus-prometheus-0 9090
            </code>
            <Tooltip
              title="copy"
              arrow
              placement="top-end"
              color="inherit"
              sx={{ marginRight: 2 }}
            >
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(
                    "kubectl port-forward prometheus-prometheus-kube-prometheus-prometheus-0 9090"
                  )
                }
              >
                <ContentPasteIcon></ContentPasteIcon>
              </IconButton>
            </Tooltip>
          </div>

          <h3>Repeat for the Prometheus alert managers:</h3>

          <div className={darkModeOn ? "codeBlock" : "codeBlock2"}>
            <code>
              kubectl port-forward
              service/prometheus-kube-prometheus-alertmanager [port #]
            </code>

            <Tooltip
              title="copy"
              arrow
              placement="top-end"
              color="inherit"
              sx={{ marginRight: 2 }}
            >
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(
                    "kubectl port-forward service/prometheus-kube-prometheus-alertmanager [port #]"
                  )
                }
              >
                <ContentPasteIcon></ContentPasteIcon>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
}

export default Documentation;
