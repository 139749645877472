import React, { useState } from 'react';
import Appbar from './components/Appbar';
import Carousel from './components/Carousel';
import About from './components/About';
import Documentation from './components/Documentation';
import Overview from './components/Overview';
import Footer from './components/Footer';
import { useTheme } from '@mui/material/styles';
import { Link } from "react-scroll";
import { Box, Typography } from '@mui/material';
import logo from './components/logo.png'

function App() {

  const theme = useTheme();

  const [ darkModeOn, setDarkModeOn ] = useState(true);

  function toggleTheme() {
    setDarkModeOn(old => !old)
  };

  // const styles ={
  //   // backgroundColor: darkModeOn ? theme.palette.primary.main : theme.palette.secondary.light,
  //   color: darkModeOn ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText,
  // }

  return (
    <div /*style={styles} */ className='app-main'> {/* width 100% */}


      <div className='logo-main'> {/* display flex, p-top 500px */}

          {/* <div id='logo-font'
           style={{
             fontFamily: 'Nabla, cursive',
             fontSize: '200px',
             color: '#fab700',
             marginBottom: '100px',
           }}
          >Kuberneat</div> */}

                        <Typography
                            id='logo'
                            component="div"
                            sx={{ flexGrow: 1, marginLeft: 3 }}
                        >
                            {/* Use the img tag to display the logo image */}
                            <img 
                            src={logo}
                             alt="Logo" 
                             style={{
                              marginRight: '10px',
                              height: '400px',
                              width: 'auto', 
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', 
                              borderRadius: '50px',
                          }} />
                        </Typography>

          <div> 
              <Link activeClass="active" smooth spy to="documentation"><div class="neon-button">Get Started</div></Link>
          </div>

      </div>
      
  
      <Box sx={{ paddingBottom: 50 }}>
        <Appbar toggleTheme={toggleTheme} darkModeOn={darkModeOn} id='appbar' />
      </Box>
      
      

     <Box sx={{ 
      height: 1000,
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      marginBottom: 30, 
      }}> 
      <Overview darkModeOn={darkModeOn}/>
     </Box> 

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        }}>
        <Carousel darkModeOn={darkModeOn}/>
      </Box>

       
      <Box  sx={{ 
        paddingBottom: 50, marginTop: 50,
        
        }}>
        <Documentation darkModeOn={darkModeOn} />
      </Box>

      <Box sx={{ paddingBottom: 10 }}>
        {/* <About darkModeOn={darkModeOn} /> */}
      </Box>

  <Footer />
      
    </div>
  );

}

export default App;
