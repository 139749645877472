import * as React from "react";
import YoutubeEmbed from "./YoutubeEmbed";
import { Box, Typography, Stack } from "@mui/material";
import Background from "./Background";

function Overview({ darkModeOn }) {
  return (
    <div id="overview">
      <Box sx={{ padding: 5 }}>
        <div className={darkModeOn ? "overview" : "overview2"}>
          <div className="video-box0">
            {/* <Typography> 
                  <h1 >
                      Introducing Kuberneat, a visual GUI showing metrics available from Grafana, 
                    a D3 cluster diagram, and individual node metrics.
                  </h1>
               </Typography>   */}
            <Typography
              variant="body1"
              sx={{
                fontSize: "2rem",
                lineHeight: "3rem",
                textAlign: "center",
                fontWeight: "light",
              }}
            >
              Introducing Kuberneat, a visual GUI helping you render available
              metrics from Grafana, a D3 cluster diagram for visualization, and
              individual node metrics to help make more informed data-driven
              decisions.
            </Typography>
            {/* <Background /> */}
          </div>

          <div className="video-box1">
            <YoutubeEmbed className="video" embedId="6OOqJIoEb3Y" />
          </div>
          <div className="video-box2">
            {/* <Typography> <h1>Check out our Github and repo </h1>
                  <a id='href' href = {"https://github.com/oslabs-beta/Zeus"} target="_blank" rel="noreferrer"><h1> here</h1></a> <h1>to get started.</h1> 
                </Typography> */}

            <Typography sx={{ color: "gold" }}>
              <p>
                Check out our Github and repo{" "}
                <a
                  href="https://github.com/oslabs-beta/Kuberneat"
                  style={{
                    textDecoration: "none",
                    color: "violet",
                    transition: "color 0.3s",
                  }}
                  onMouseEnter={(e) => (e.target.style.color = "purple")} // Change color on hover
                  onMouseLeave={(e) => (e.target.style.color = "violet")} // Restore color on mouse leave
                >
                  Here
                </a>
              </p>
            </Typography>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default Overview;
